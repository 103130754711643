import { Circle } from "@chakra-ui/react";
import { Center, VStack, HStack, Image, Badge } from "@chakra-ui/react";
import { CameraIcon, CubeIcon } from "@heroicons/react/20/solid";

import {
  PhotoIcon,
  ShareIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useState } from "react";
import { reverseGeocode } from "../services/ReverseGeocode";

function ContainerMapResult({
  isLoading,
  shareAction,
  notFoundAction,
  containers,
}) {
  let [locationString, setLocationString] = useState("...");

  useEffect(() => {
    const container = containers[0];
    async function findLocationString() {
      var locationString = await reverseGeocode(
        container.gps_lat,
        container.gps_lon
      );
      setLocationString(locationString);
    }

    findLocationString().catch((e) => {
      setLocationString("");
      console.error(e);
    });
  }, [containers]);

  const chains = {
    P: { fullName: "Polygon", explorer: "https://polygonscan.com/tx/" },
  };

  return (
    <>
      <div className="flex w-full h-full">
        <VStack
          className="overflow-y-auto w-96 h-full text-white rounded-xl shadow-md bg-traxa"
          alignItems="start"
        >
          {!isLoading && containers.length > 0 && (
            <>
              <Image
                src={containers[0].image}
                fallback={
                  <div className="w-full bg-gray-400 rounded-t-xl aspect-video"></div>
                }
                className="object-cover w-full rounded-t-xl aspect-video"
              />
              <VStack className="px-6 pt-4" alignItems="start" spacing={1}>
                <HStack>
                  <h1 className="text-xl leading-none">{containers[0].text}</h1>
                  <Badge
                    variant="outline"
                    className="!border-white !text-white"
                  >
                    {containers[0].type.includes("Vertical")
                      ? "Vertical"
                      : "Horizontal"}
                  </Badge>
                </HStack>
                {locationString !== "" && <span>{locationString}</span>}
                <span className="text-purple-300">
                  Last seen:{" "}
                  {new Date(
                    parseInt(containers[0].timestamp_ms)
                  ).toLocaleString()}
                </span>
              </VStack>
              <VStack className="w-full" spacing={0} alignItems="start">
                <VStack spacing={0} className="pb-2 w-full">
                  <HStack
                    className="px-6 w-full h-9 cursor-pointer hover:bg-white/10"
                    onClick={() => shareAction()}
                  >
                    <ShareIcon className="mr-4 w-5" />
                    <span>Share container</span>
                  </HStack>

                  <HStack className="relative px-6 w-full h-9 cursor-pointer hover:bg-white/10">
                    <PhotoIcon className="mr-4 w-5" />
                    <a
                      href={containers[0].image}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="absolute inset-0"></span>
                      View on arweave
                    </a>
                  </HStack>
                </VStack>
                <div className="p-6 w-full bg-black/20">
                  <HStack>
                    <h2 className="text-sm font-medium leading-none text-white/90">
                      Owner NFT
                    </h2>
                    <span className="flex-grow"></span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        chains[containers[0].owner.chain].explorer +
                        containers[0].owner.contract
                      }
                    >
                      <ArrowTopRightOnSquareIcon className="w-5 text-gray-400 hover:text-white"></ArrowTopRightOnSquareIcon>
                    </a>
                  </HStack>

                  <HStack className="mt-6 w-full" spacing={4}>
                    <Image
                      src={containers[0].owner.image}
                      fallback={
                        <div className="h-12 bg-gray-400 rounded-xl aspect-video"></div>
                      }
                      className="object-cover h-12 rounded-xl aspect-video"
                    />
                    <VStack alignItems="start" spacing={1}>
                      <span className="truncate">
                        {containers[0].owner.name}
                      </span>
                      <Badge variant="outline" colorScheme="orange">
                        {chains[containers[0].owner.chain].fullName}
                      </Badge>
                    </VStack>
                  </HStack>
                </div>

                {containers.length > 1 && (
                  <div className="flex flex-col p-6 w-full">
                    <h2 className="mb-6 text-sm font-medium leading-none text-white/90">
                      Location history
                    </h2>

                    {containers.map((it) => (
                      <>
                        <HStack className="w-full" spacing={4}>
                          <Circle size="12px" bg="orange.500" />
                          <VStack
                            alignItems="start"
                            spacing={0}
                            className="p-3 w-full rounded-xl cursor-pointer hover:bg-white/10"
                          >
                            <span>
                              Lat: {it.gps_lat} - Lon: {it.gps_lon}
                            </span>
                            <span className="text-purple-300">
                              {new Date(
                                parseInt(it.timestamp_ms)
                              ).toLocaleString()}
                            </span>
                          </VStack>
                        </HStack>

                        <div className="-my-4 ml-1 w-1 h-10 bg-orange-500 rounded-full"></div>
                      </>
                    ))}
                  </div>
                )}
              </VStack>
            </>
          )}

          {!isLoading && containers.length === 0 && (
            <>
              <VStack
                className="p-6 text-purple-200"
                alignItems="start"
                spacing={2}
              >
                <h1 className="text-xl font-medium leading-none text-white">
                  Container Not Found 😅
                </h1>
                <span>
                  You beat us to it! This container is not yet in the
                  marketplace.
                </span>
              </VStack>

              <VStack className="p-6 bg-black/20">
                <span className="font-medium text-white">
                  Do you know you can help us find it, and earn by doing it?
                  Respond to this form and let's get in touch!
                </span>

                <VStack spacing={4} className="py-6 w-full">
                  <HStack
                    onClick={() => {
                      notFoundAction(
                        "https://app.vbout.com/traxa-dao-llc/f/88003"
                      );
                    }}
                    alignItems="center"
                    className="px-8 py-6 w-full rounded-xl transition-all cursor-pointer hover:shadow-md hover:bg-gradient-to-tr hover:from-purple-500 hover:to-orange-500 bg-white/10 border-black/30"
                  >
                    <CubeIcon className="mr-4 w-6 text-white" />
                    <VStack alignItems="start" spacing={0}>
                      <h1 className="text-lg text-white">I am a shipper</h1>
                      <span>I want to track containers</span>
                    </VStack>
                  </HStack>

                  <HStack
                    onClick={() => {
                      notFoundAction(
                        "https://app.vbout.com/traxa-dao-llc/f/88250"
                      );
                    }}
                    className="px-8 py-6 w-full rounded-xl transition-all cursor-pointer hover:shadow-md hover:bg-gradient-to-tr hover:from-purple-500 hover:to-orange-500 bg-white/10 border-black/30"
                  >
                    <CameraIcon className="mr-4 w-6 text-white" />
                    <VStack alignItems="start" spacing={0}>
                      <h1 className="text-lg text-white">I am a contributor</h1>
                      <span>I want to scan containers</span>
                    </VStack>
                  </HStack>
                </VStack>
              </VStack>
            </>
          )}

          {isLoading && (
            <Center className="w-96 h-full text-white rounded-xl shadow-md bg-traxa">
              <Circle size="40px" className="bg-purple-200 animate-pulse" />
            </Center>
          )}

          <HStack className="flex p-6 !items-end w-full h-full text-sm text-purple-400">
            <a href="/terms" className="hover:underline">
              Terms
            </a>
            <a href="/privacy" className="hover:underline">
              Privacy
            </a>
            <span>|</span>
            <span>© 2023 Traxa DAO, LLC</span>
          </HStack>
        </VStack>
      </div>
    </>
  );
}

export default ContainerMapResult;
