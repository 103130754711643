import { BrowserRouter, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Landing from "./pages/Landing";
import Map from "./pages/Map";
import { Routes } from "react-router-dom";
import theme from "./theme";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "./utils/ga-utils";
import { useEffect } from "react";
import Stats from "./pages/Stats";
import RecentContainers from "./pages/RecentContainers";

function App() {
  const handleAcceptCookie = () => {
    initGA("G-JDF8STYKR6");
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/map" element={<Map />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/latestcontainers" element={<RecentContainers />} />
          <Route path="/" element={<Landing />} />
        </Routes>
      </BrowserRouter>
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This website uses cookies to enhance the user experience
      </CookieConsent>
    </ChakraProvider>
  );
}

export default App;
