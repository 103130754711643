import { HStack } from "@chakra-ui/react";
import { Thead } from "@chakra-ui/react";
import { Th } from "@chakra-ui/react";
import { Td } from "@chakra-ui/react";
import { Img } from "@chakra-ui/react";
import { Tbody } from "@chakra-ui/react";
import { Tr } from "@chakra-ui/react";
import { Table } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import ContactLinks from "../components/ContactLinks";

function Privacy() {
  document.title = "Traxa | Privacy Policy";

  return (
    <VStack h="100vh" w="100vw" className="py-4 text-purple-200 bg-traxa">
      <HStack>
        <a href="/">
          <Img
            src="logo_white.png"
            alt="Traxa logo"
            className="aspect-[770/256]"
            maxW="500px"
            width="100px"
          />
        </a>
      </HStack>

      <h1 className="px-16 py-16 w-full text-4xl font-bold text-center text-white bg-gradient-to-tr from-purple-500 to-orange-500">
        Privacy Notice
      </h1>

      <VStack className="px-16 py-8 md:w-2/3" align="start">
        <p>
          The Traxa DAO, LLC (“we”, “our” or “Traxa”)¹ respect your right to
          privacy. This Privacy Notice explains how we collect, store, process,
          share, and use personal information relating to you, and how you can
          exercise your privacy rights. This Privacy Notice does not apply to
          personal data Traxa processes in the role of a processor or service
          provider on behalf of our customers. In this Privacy Notice, personal
          information means any information that relates to an individual who
          can be identified directly, or indirectly in combination with other
          data. This Privacy Notice applies to the personal data we collect and
          process about:
        </p>

        <ul className="space-y-4 list-disc">
          <li>
            Individuals who use our products and services (for example, as an
            employee of one of our customers);
          </li>
          <li>
            Individuals who are our business contacts or otherwise receive
            communications from us (including electronic communications, phone
            calls, or texts);
          </li>
          <li>
            Individuals who express an interest in our products or services,
            attend or express an interest in events or webinars hosted,
            sponsored, or attended by Traxa, participate in Traxa hosted forum,
            communities, or open source development related to Traxa products
            and services;
          </li>
          <li>
            Individuals who visit our websites, our premises, facilities or
            offices;
          </li>
          <li>
            Individual who are our contacts at our service providers; our
            business partners with whom we collaborate; and other third-parties
            with whom we interact in the ordinary course of business.
          </li>
        </ul>

        <p>
          This Privacy Notice does not apply to the personal information
          collected and processed by Traxa about its employees, which is
          governed by a separate privacy notice. It also does not apply to
          personal information collected and processed by Traxa for recruitment
          purposes, or about applicants to our job offers.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          What personal information does Traxa collect and why
        </h2>

        <p className="font-medium text-white">
          Information that you provide voluntarily
        </p>

        <p>
          You voluntarily submit information directly to us when you use our
          products or services. This can include information you provide to us
          when you register for an account, fill in a form on our Website,
          create or edit your user profile, correspond with us by phone, e-mail
          or otherwise, subscribe to our mailing lists, newsletters or other
          forms of marketing communications, respond to a survey, enter a
          promotion or use some other feature of our products or services. If
          you choose not to provide personal information, we may not be able to
          provide you with our products or services, or respond to your
          requests. We have described in the below table the types of
          information we ask you to provide, and the reasons why we ask you to
          provide it. If we ask you to provide any other personal information
          not described in the table below, then the personal information we
          will ask you to provide, and the reasons why we ask you to provide it,
          will be made clear to you at the point we collect your personal
          information.
        </p>

        <Table className="w-full" variant="simple">
          <Thead>
            <Tr>
              <Th className="!text-white">Types of personal information</Th>
              <Th className="!text-white">Why we collect it</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                Identification, contact, and employment information, including
                your full name, professional email address, professional postal
                address, professional telephone number, company name, job title
                or area of expertise, country of location, username and
                password.
              </Td>
              <Td>
                <ul className="space-y-4 list-disc">
                  <li>
                    Establish and manage our relationship with our customers,
                    service providers, distributors and other partners,
                  </li>
                  <li>
                    Manage, operate, and maintain the products or services we
                    provide to you or receive from you (or your employer),
                    including to schedule meetings and calls, send technical
                    notes, documentation, statements and invoices, address
                    inquiries and complaints, provide information about
                    available updates of our products, send security alerts when
                    necessary, and determine eligibility to use our products or
                    services.
                  </li>
                  <li>
                    Inform you of our products, services, offers, events and
                    webinars that we believe may be of interest to you, through
                    means of communication such as e-mails, marketing
                    newsletters, telemarketing calls, and SMS, as necessary for
                    our legitimate interest in conducting direct marketing or to
                    the extent you have provided your prior consent, when
                    required by law. Notably, if you purchase our products or
                    services, we may communicate with you to inform you about
                    similar products or services. You may opt-out from such
                    communications at any time by clicking on the link at the
                    bottom of our marketing e-mails.
                  </li>
                  <li>
                    If you visit our premises, to prevent unauthorized access to
                    our premises, protect the health and safety of our staff and
                    visitors, and to detect and prevent fraud, criminal behavior
                    or misconduct on our premises.
                  </li>
                </ul>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Marketing preferences including the contact information you
                would like Traxa to use for marketing purposes, your marketing
                content preferences, and whether you opted out of our marketing
                communications.
              </Td>
              <Td>
                <ul className="space-y-4 list-disc">
                  <li>
                    Manage your preferences in terms of communications you
                    receive from Traxa, and to provide you with content adapted
                    to your preferences.
                  </li>
                  <li>
                    Comply with laws and regulations restricting marketing
                    communications.
                  </li>
                </ul>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Communication information: when you are communicating with a
                Traxa staff member, for example by email, phone, or by
                completing an online form or participating in online chat or
                forum, in the context of a support case, for an inquiry, or to
                register to a Traxa event or webinar. We will record the content
                of the communication, including your comments and opinions, as
                well as the date and time of the communication, and the medium
                used to send it.
              </Td>
              <Td>
                <ul className="space-y-4 list-disc">
                  <li>
                    Address your requests, comments, issues, questions, or
                    concerns
                  </li>
                  <li>
                    Improve our interactions with you and better meet your
                    expectations, notably in the context of support cases
                  </li>
                  <li>
                    Determine the products and services that may be of interest
                    to you and to send you communications in accordance with
                    your interest
                  </li>
                  <li>
                    Prevent fraud and phishing attacks targeting Traxa or
                    members of Traxa staff by assessing whether we are
                    communicating with genuine contacts and not malicious
                    parties.
                  </li>
                </ul>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Information shared on Traxa Community: if you register for our
                online Traxa Community, we may ask you to provide a username, as
                well as other biographical information, such as your occupation,
                location, social media profiles, company name, areas of
                expertise and professional interests.
              </Td>
              <Td>
                Our website hosts Traxa Community, a forum dedicated for
                questions and discussion around our products and services. Any
                personal information that you choose to submit via this forum
                may be read by us, or by others who visit this forum.
              </Td>
            </Tr>
            <Tr>
              <Td>
                Payment and transaction information, including information such
                as the service level purchased, date and time of your
                transaction, and payment information, such as your credit card
                or bank account details.
              </Td>
              <Td>
                Facilitate transactions, detect and prevent fraud and inform our
                advertising and marketing.
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <p className="font-medium text-white">
          Information that we obtain from third party sources
        </p>

        <p>
          From time to time, we may receive some of the personal information
          listed in this notice from third party sources, including from your
          employer or the company you are affiliated with, third party suppliers
          from which we purchase personal information, and partners, including
          partners of our Technology Alliance Program, Value Added Resellers,
          and co-organizers and sponsors to Traxa events and webinars.
        </p>

        <p>
          We may combine the information we received from third-party sources
          with personal information provided by you, or that we automatically
          collect about you (see table below). This helps us update, expand, and
          analyze our records, identify new customers, and create more tailored
          advertising about the products and services that may be of interest to
          you. If you believe that your personal information has been provided
          to us by a third party improperly, or want to exercise your rights
          relating to your personal information, please contact us at
          privacy@traxa.io.
        </p>

        <p>
          We may also collect some of the personal information listed in this
          notice through social media platforms, such as Facebook, LinkedIn, or
          Twitter. This personal information is the information that you choose
          to make publicly available on these platforms, and may include your
          name, email address, and other biographical information such as job
          title , company name, area of expertise and professional interests.
        </p>

        <p>
          Traxa may also acquire your personal information in the course of
          mergers and acquisitions of another company or business.
        </p>

        <p className="font-medium text-white">
          Information that we collect automatically
        </p>

        <Table className="w-full">
          <Thead>
            <Tr>
              <Th className="!text-white">Types of personal information</Th>
              <Th className="!text-white">Why we collect it</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>
                Usage data relating to the access and use of our cloud products
                or services, including: login information, such as IP address,
                login credentials, date and time of access, country of location,
                browser version, days active, time on site; type of license and
                product used, features used, buttons clicked on the interface,
                jobs performed, debug run; support information, including error
                messages, configurations issues, and support case reported.
              </Td>
              <Td>
                <ul className="space-y-4 list-disc">
                  <li>
                    Monitor subscriptions to our products and services,
                    including to ensure that only licensed users use our
                    software and to determine relevant subscription fees due
                    based on numbers of licensed users , to review compliance
                    with applicable usage terms, and to maintain the security of
                    our products and services.
                  </li>
                  <li>
                    Understand, assess, and improve the usage, performance, and
                    enjoyment of our products and services, as well as
                    engagement with our products and services, customers and
                    users experience, and capacity requirements, including to
                    determine the products and services that may be of interest
                    to you for marketing purposes.
                  </li>
                  <li>
                    Products and services development and enhancement, notably
                    to identify future opportunities for development of our
                    products or services, to develop new features or
                    functionalities, to identify customers and users
                    opportunities, and to provide users with more pertinent
                    advertisements, content or features.
                  </li>
                </ul>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Information about your device, including information about the
                computer, tablet, smartphone or other electronic device you use
                to connect to our products and services. This information can
                include details about the type of device, unique device
                identifying numbers, operating systems and applications
                connected to our products and services through the device.
              </Td>
              <Td>
                We use information about how you use and connect to our products
                and services to present our products and services to you on your
                device and to determine products and services that may be of
                interest to you for marketing purposes.
              </Td>
            </Tr>
            <Tr>
              <Td>
                Information collected through cookies, such as your geographic
                location, and the links clicked on our websites.
              </Td>
              <Td>
                We use various cookies to ensure the functioning of our
                websites, and to help us understand how our websites are being
                used or how effective our marketing campaigns are.
              </Td>
            </Tr>
            <Tr>
              <Td>
                Information collected through automatic data collection tools
                during your visit of our websites and domains (“Website”) or use
                our products or services, including: Internet Protocol (IP)
                addresses, clickstream behavior (i.e. the pages you view, the
                links you click, and other actions you take in connection with
                the Website), certain standard information that your browser
                sends to our Website, such as browser type and language, access
                times, and the address of the website from which you arrived on
                our Website
              </Td>
              <Td>
                Collecting this information enables us to better understand the
                visitors who come to our Website or use or products and
                services, where they come from, and what content is of interest
                to them. We use this information to analyze overall trends, to
                improve the quality and relevance of our Website, products, and
                services, and to secure and maintain our systems. This
                information also helps us understand marketing campaign
                effectiveness.
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <h2 className="pt-8 text-lg font-bold text-white">
          Who does Traxa share my personal information with?
        </h2>
        <p>
          Traxa may share your personal information with the following
          categories of recipients:
        </p>
        <p className="font-medium text-white">Group Companies</p>
        <ul className="space-y-4 list-disc">
          <li>
            Traxa shares information with entities within the Traxa DAO, LLC. A
            list of our current group companies is available in Exhibit 21.1 of
            our 10-K filed with the SEC.
          </li>
          <li>
            Partners and Agents
            <ul className="my-4 ml-8 space-y-4 list-disc">
              <li>
                Third-Party Service Providers: we may share your information
                with our contracted service providers, who provide services such
                as IT and system administration and hosting, research and
                analytics, marketing, customer support and data enrichment for
                the purposes and pursuant to the legal basis described in this
                notice;
              </li>
              <li>
                Professional Advisers: in individual instances, we may share
                your personal information with professional advisers acting as
                service providers, processors, or controllers – including our
                lawyers, bankers, auditors, and insurers;
              </li>
              <li>
                Events and Webinars Sponsors: if you attend an event or webinar
                organized or co-organized by us, or download or access an asset
                on our Website, we may share your personal information with
                sponsors of the event or webinar. If required by applicable law,
                you may consent to such sharing via the registration form or by
                allowing your attendee badge to be scanned. In these
                circumstances, the sponsors will also be a controller of your
                information, which will be subject to the sponsors’ privacy
                statements;
              </li>
              <li>
                Partners from Traxa Technology Alliance Program and Value Added
                Resellers: if you choose to interact with or use our partners or
                value added resellers tools, services or technology, we may
                share your personal information with our them, and they may
                contact you regarding their products or services;
              </li>
              <li>
                Customers with whom you are affiliated: if you use our products
                or services as an authorized user of one of our customers, we
                may share your personal information with your affiliated
                customer responsible for your access to the products or
                services, to the extent necessary for providing our services.
              </li>
            </ul>
          </li>
        </ul>
        <p className="font-medium text-white">
          Legal Requirements and Investigations
        </p>
        <ul className="space-y-4 list-disc">
          <li>
            Traxa will disclose your personal information to any competent law
            enforcement body, regulatory, government agency, court or other
            third party where we believe disclosure is necessary (i) as a matter
            of applicable law or regulation, including to meet national security
            or law enforcement requirements, (ii) to exercise, establish or
            defend our legal rights, including to comply with legal obligations
            such as subpoenas, court orders or search warrants, and as otherwise
            authorized by law, (iii) to protect your vital interests or those of
            any other person;
          </li>
        </ul>
        <p className="font-medium text-white">Sale of Assets</p>
        <ul className="space-y-4 list-disc">
          <li>
            Traxa may transfer personal information to a potential buyer (and
            its agents and advisers) in connection with any proposed purchase,
            merger or acquisition of any part of our business, provided that we
            inform the buyer it must use your personal information only for the
            purposes disclosed in this Privacy Notice.
          </li>
        </ul>

        <h2 className="pt-8 text-lg font-bold text-white">
          Legal basis for processing personal information
        </h2>

        <p>
          Our legal basis for collecting and using the personal information
          described above will depend on the personal information collected and
          the specific context in which we collect it.
        </p>

        <p>
          We will normally collect personal information from you only where we
          have your consent to do so, where we need the personal information to
          perform a contract with you, or where the processing is in our
          legitimate interests and not overridden by your data protection
          interests or fundamental rights and freedoms.
        </p>

        <p>
          In some cases, we may also have a legal obligation to collect personal
          information from you or may otherwise need the personal information to
          protect your vital interests or those of another person.
        </p>

        <p>
          Mostly, however, we process your personal information for our
          legitimate business interests as described in the Section What
          personal information does Traxa collect and why?. If we collect and
          use your personal information in reliance on any legal basis other
          than our legitimate interests, we will make it clear to you at the
          relevant time. For example, if we ask you to provide personal
          information to comply with a legal requirement or to perform a
          contract with you, we will make this clear at the relevant time and
          advise you whether the provision of your personal information is
          mandatory or not (as well as of the possible consequences if you do
          not provide your personal information).
        </p>

        <p>
          If you have questions about or need further information concerning the
          legal basis on which we collect and use your personal information,
          please contact us at privacy@traxa.io.
        </p>

        <p>
          Our products and services are not directed at persons under 16 and we
          do not knowingly collect personal information from children under 16.
          If you become aware that your child has provided us with personal
          information, without your consent, then please contact us using the
          details below so that we can take steps to remove such information and
          terminate any account your child has created with us.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          Automated Decision Making and Profiling
        </h2>

        <p>
          If you have been a visitor to our websites, we use an automated tool
          at the early stage of our communication process to qualify your
          request. This tool appears as a communication box on Traxa websites,
          which you can use to ask any questions or requests you may have about
          Traxa products or services. The purpose of this tool is to identify
          the most appropriate person at Traxa to respond to your specific
          questions or requests. This tool would not have any legal or other
          significant effect, and you can always obtain to interact directly
          with one of our representatives.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          How does Traxa keep my personal information secure?
        </h2>

        <p>
          We take the security of your personal information very seriously. We
          use appropriate technical and organizational measures to protect the
          security of the personal information that we collect and process about
          you from unauthorized disclosure. The measures we use are designed to
          provide a level of security appropriate to the risk of processing your
          personal information.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          International data transfers
        </h2>

        <p>
          Traxa may share your personal information within the Traxa DAO, LLC of
          companies for a variety of purposes, for example to provide you with
          the latest information about our products and services. To facilitate
          our global operations, your personal information may be transferred
          to, stored, and/or processed in countries other than the country in
          which you are resident, and that may be outside of the European
          Economic Area. A list of the countries where our current group
          companies are incorporated is available in Exhibit 21.1 of our 10-K
          filed with the SEC.
        </p>

        <p>
          Specifically, our servers are located in the US and in Germany, and
          our group companies, third party service providers and partners
          operate in countries worldwide. This means that when we collect your
          personal information, we may process it in any of these countries.
        </p>

        <p>
          To protect your personal information, we will generally transfer data
          to countries subject to an adequacy decision by the European
          Commission. In those situations where your personal information is
          transferred to countries not subject to an adequacy decision by the
          European Commission, we have taken appropriate safeguards to ensure
          that your personal information remains protected in accordance with
          this Privacy Notice.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">Data retention</h2>

        <p>
          We retain personal information we collect from you for a period of
          time consistent with the original purpose of collection. For example,
          if we collect your personal information for our legitimate business
          interest, we will retain your personal information for only as long as
          there is an ongoing legitimate business need to do so, (e.g., to
          receive services from you (or your employer), to provide services to
          our customers, or to collaborate with our partners).
        </p>
        <p>
          When the original purpose no longer exists, and the applicable
          retention periods have expired, we will either delete or anonymize
          your personal information or, if this is not possible (for example,
          because your personal information has been stored in backup archives),
          then we will securely store your personal information and isolate it
          from any further processing until deletion is possible.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          Your data protection rights
        </h2>

        <p>
          In accordance with the European privacy laws, EEA and Swiss data
          subjects have the following data protection rights². If you wish to
          access, correct, update or request deletion of your personal data, you
          can do so at any time by contacting us at privacy@traxa.io.
        </p>

        <ul className="space-y-4 list-disc">
          <li>
            If you wish to access, correct, update or request deletion of your
            personal information, you can do so at any time by contacting us at
            privacy@traxa.io.
          </li>
          <li>
            In addition, you can object to processing of your personal
            information, ask us to restrict processing of your personal
            information or request portability of your personal information.
            Again, you can exercise these rights by contacting us at
            privacy@traxa.io.
          </li>
          <li>
            You have the right to opt-out of marketing communications we send
            you at any time.
          </li>
          <li>
            Similarly, if we have collected and process your personal
            information with your consent, you can withdraw your consent at any
            time. Withdrawing your consent will not affect the lawfulness of any
            processing we conducted prior to your withdrawal, nor will it affect
            processing of your personal information conducted in reliance on
            lawful processing grounds other than consent.
          </li>
          <li>
            You have the right to complain to a data protection authority about
            our collection and use of your personal information. For more
            information, please contact your local data protection authority
            (contact details for data protection authorities in the European
            Economic Area, Switzerland and certain non-European countries
            (including the US and Canada) are available.
          </li>
        </ul>
        <p>
          We respond to all requests we receive from individuals wishing to
          exercise their data protection rights in accordance with applicable
          data protection laws.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          CCPA Compliance at Traxa
        </h2>

        <p>
          As a California business, Traxa complies with the California Consumer
          Privacy Act (“CCPA”) with respect to the personal information listed
          in this Privacy Notice and relating to California consumers.
        </p>
        <p>
          As explained in the preamble, Traxa only collects and processes
          personal information in the context of the business relationship
          between Traxa and the organization or business to which you are
          affiliated or employed, usually because Traxa provides or receives a
          product or service to or from such organization or business. This
          means that certain CCPA consumer rights may not be available with
          respect to the personal information collected by Traxa.
        </p>
        <p>
          Nonetheless, Traxa does not sell personal information and does not
          discriminate against any California consumer for exercising their
          rights under the CCPA.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          Updates to this Privacy Notice
        </h2>

        <p>
          We may update this Privacy Notice from time to time in response to
          changing legal, technical or business developments. When we update our
          Privacy Notice, we will take appropriate measures to inform you,
          consistent with the significance of the changes we make. We will
          obtain your consent to any material Privacy Notice changes if and
          where this is required by applicable data protection laws.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">How to contact us</h2>

        <p>
          If you have any questions or concerns about our use of your personal
          information, please contact us at privacy@traxa.io. If you would like
          to submit a request regarding your personal data, please contact
          privacy@traxa.io.
        </p>
      </VStack>

      <ContactLinks className="pt-24 pb-16" />
    </VStack>
  );
}

export default Privacy;
