async function reverseGeocode(lat, lon) {
  let result = await fetch(
    `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lon}&zoom=18&format=jsonv2`
  );

  let data = await result.json();

  return data.display_name;
}

export { reverseGeocode };
