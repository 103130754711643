import {
  Image,
  HStack,
  Box,
  Badge,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
const now = new Date().getTime();

function ContainerCard({ container }) {
  const elapsed = now - parseInt(container.timestamp_ms);
  const elapsedString = (it) => {
    it = it / 1000;

    if (it <= 0) return "Right now";
    if (it < 60) return it + " seconds ago";
    if (it > 60 && it < 3600) return Math.floor(it / 60) + " minutes ago";
    if (it > 3600 && it < 24 * 3600)
      return Math.floor(it / 3600) + " hours ago";
    if (it / (24 * 3600) < 30)
      return Math.floor(it / (24 * 3600)) + " days ago";
    if (it / (24 * 3600) < 30 * 12)
      return Math.floor(it / (30 * 24 * 3600)) + " months ago";

    return "More than 1 year ago";
  };

  return (
    <Box
      p="5"
      minW="300px"
      borderWidth="1px"
      className="rounded-full transition-colors hover:bg-white/5 bg-black/25 border-black/30"
    >
      <HStack>
        <Image
          className="w-12 bg-gray-200 rounded-full aspect-square -p-1"
          src={container.image}
        ></Image>
        <div className="pl-2 text-purple-100/50">
          <span className="font-medium text-white">{container.text}</span>{" "}
          <Badge variant="outline" className="!border-white !text-white">
            {container.type.includes("Vertical") ? "Vertical" : "Horizontal"}
          </Badge>
          <br />
          {elapsedString(elapsed)}
        </div>
      </HStack>
    </Box>
  );
}

function SkeletonCard() {
  return (
    <Box
      p="5"
      minW="300px"
      borderWidth="1px"
      className="rounded-full transition-colors hover:bg-white/5 bg-black/25 border-black/30"
    >
      <HStack>
        <SkeletonCircle size={12} />
        <SkeletonText className="flex-grow" ml="2" noOfLines={2} spacing="1" />
      </HStack>
    </Box>
  );
}

export { ContainerCard, SkeletonCard };
