import { Input } from "@chakra-ui/react";
import { HStack } from "@chakra-ui/react";
import { Img } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import { Button, Center } from "@chakra-ui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  ChatBubbleBottomCenterIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import { LatestContainers } from "../components/LatestContainers";
import Marquee from "react-fast-marquee";
import ContactLinks from "../components/ContactLinks";
import { FrameDialog } from "../components/FrameDialog";
import { useDisclosure } from "@chakra-ui/react";

function Landing() {
  document.title = "Traxa | Track containers";
  const [query, setQuery] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [frameUrl, setFrameUrl] = useState();

  return (
    <>
      <FrameDialog
        onClose={onClose}
        isOpen={isOpen}
        pageUrl="https://app.vbout.com/traxa-dao-llc/f/84442"
      />
      <Center h="100vh" w="100vw" className="bg-traxa">
        <VStack
          className="px-8 w-full sm:w-8/12 md:w-7/12 lg:w-4/12"
          spacing="8"
        >
          <Img
            src="logo_white_beta.png"
            alt="Traxa logo"
            className="aspect-[770/256]"
            maxW="500px"
            width="60%"
          />

          <VStack className="relative w-full" spacing={6}>
            <HStack className="w-full">
              <Input
                placeholder="Container number"
                text={query}
                className="!rounded-full placeholder:!text-white/80 !text-white !border-2"
                onChange={(e) => setQuery(e.currentTarget.value)}
                size="md"
              />
              <Link to={query ? "/map?containerId=" + query : "/map"}>
                <Button
                  variant="solid"
                  colorScheme="orange"
                  children="Track"
                  className="!bg-gradient-to-tr from-purple-500 to-orange-500 !rounded-full"
                  leftIcon={<MagnifyingGlassIcon width={20} />}
                />
              </Link>
            </HStack>
            <div className="w-full sm:w-[200%]">
              <Marquee
                speed={4}
                delay={5}
                gradientWidth={50}
                gradientColor={[39, 1, 112]}
                pauseOnHover
              >
                <LatestContainers count={10} />
              </Marquee>
            </div>
          </VStack>

          <HStack spacing={8} className="fixed top-0 left-8">
            {/* <Link to={query ? "/map?containerId=" + query : "/map"}>
              <Button
                variant="link"
                colorScheme="whiteAlpha"
                children="Contribute and earn"
                leftIcon={<MegaphoneIcon width={20} />}
              />
            </Link> */}
            <Button
              variant="link"
              colorScheme="whiteAlpha"
              children="Contact us"
              onClick={() => onOpen()}
              leftIcon={<ChatBubbleBottomCenterIcon width={20} />}
            />
          </HStack>

          <ContactLinks className="fixed bottom-12" />
        </VStack>
      </Center>
    </>
  );
}

export default Landing;
