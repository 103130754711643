import GA4React from "ga-4-react";

var analytics;

export const initGA = (id) => {
  try {
    analytics = new GA4React(id);
    analytics.initialize().catch((err) => console.error(err));
    console.log("Analytics initialized");
  } catch (err) {
    console.error(err);
  }
};

export function sendSearchEvent(query) {
  analytics.gtag("event", "container_search", {
    query: query,
  });
}
