import Marquee from "react-fast-marquee";
import { LatestContainers } from "../components/LatestContainers";
import "../reset.css";

function RecentContainers() {
  return (
    <>
      <Marquee speed={4} delay={5} gradientWidth={0} pauseOnHover>
        <LatestContainers count={10} />
      </Marquee>
    </>
  );
}

export default RecentContainers;
