import { HStack } from "@chakra-ui/react";
import { Img } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import ContactLinks from "../components/ContactLinks";

function Terms() {
  document.title = "Traxa | Terms of Use";

  return (
    <VStack h="100vh" w="100vw" className="py-4 text-purple-200 bg-traxa">
      <HStack>
        <a href="/">
          <Img
            src="logo_white.png"
            alt="Traxa logo"
            className="aspect-[770/256]"
            maxW="500px"
            width="100px"
          />
        </a>
      </HStack>

      <h1 className="px-16 py-16 w-full text-4xl font-bold text-center text-white bg-gradient-to-tr from-purple-500 to-orange-500">
        Legal Terms of Use
      </h1>

      <VStack className="px-16 py-8 md:w-2/3" align="start">
        <p>
          Please Read Carefully Before Using This Website: The following terms
          and conditions (“Terms of Use”) govern your use of the
          trackmycontainer.io power by Traxa (“Traxa”) Website (the “Site”). By
          using the Site and/or any information, content or materials made
          available on the Site, you irrevocably agree that such use is subject
          to these Terms of Use. If you do not agree to these Terms of Use, you
          may not use the Site. Traxa expressly reserves the right to modify the
          Terms of Use at any time in its sole discretion, and without notice to
          you, by including such alteration and/or modification in these Terms
          of Use. Any continued use by you of the Site after the posting of such
          modified Terms of Use shall be deemed to indicate your irrevocable
          agreement to such modified Terms of Use. Accordingly, if at any time
          you do not agree to be subject to any modified Terms of Use, you may
          no longer use the Site.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">Site Content</h2>

        <ul className="space-y-4 list-decimal">
          <li>
            Traxa Content: Except as may be otherwise noted, the information,
            materials (including, without limitation, HTML, text, audio, video,
            white papers, press releases, data sheets, product descriptions,
            software and FAQs and other content) available on or from this
            website (collectively, “Traxa Content”) are the copyrighted works of
            Traxa and its licensors, and Traxa and its licensors expressly
            retain all right title and interest in and to the Traxa Content,
            including, without limitation, all intellectual property rights
            therein and thereto. Except as expressly permitted in these Terms of
            Use, any use of the Traxa Content may violate copyright and/or other
            applicable laws.
          </li>
          <li>
            Third Party Content: In addition to Traxa Content, the Site may
            contain information and materials provided to Traxa by third parties
            (collectively, “Third Party Content”). Third Party Content is the
            copyrighted work of its owner, which expressly retains all right
            title and interest in and to the Third Party Content, including,
            without limitation, all intellectual property rights therein and
            thereto. In addition to being subject to these Terms of Use, Third
            Party Content may also be subject to different and/or additional
            terms of use and/or privacy policies of such third parties. Please
            contact the appropriate third party for further information
            regarding any such different and/or additional terms of use
            applicable to Third Party Content.
          </li>
          <li>
            Limited Site Content License: Except as provided in Section 2.
            below, Traxa grants you the limited, revocable, non-transferable,
            non-exclusive right to use the Site and the associated Traxa Content
            and Third Party Content (collectively, “Site Content”) by displaying
            the Site Content on your computer, and downloading and printing
            pages from the Site under the condition that (i) such activity is
            solely for your personal, education or other non-commercial use,
            (ii) you do not modify or prepare derivative works from the Site
            Content, (iii) you do not obscure, alter or remove any notice of
            copyright set forth on any Site pages or Site Content, (iv) you do
            not otherwise reproduce, re-distribute or publicly display any of
            the Site Content and (v) you do not copy any Site Content to any
            other media or other storage format.
          </li>
          <li>
            User Content: If you elect to display, post, submit or otherwise
            make available to others, on the Site any content or works of
            authorship, including, without limitation, images, software, audio
            files, text, or any other materials (collectively, “User Content”),
            you hereby grant to Traxa a perpetual, irrevocable, royalty-free,
            worldwide, non-exclusive right and license, including the right to
            grant sublicenses to third parties, to use, reproduce, publicly
            display, publicly perform, prepare derivative works from and
            distribute such User Content, for any purpose. In addition, you
            hereby irrevocably represent and warrant to Traxa that (i) you have
            all necessary power, authority, right, title and/or licenses to
            grant to Traxa the foregoing right and license and (ii) the posting,
            submission, display by you of User Content on the Site, and the
            exercise by Traxa of the foregoing license does not and will not (1)
            violate any law or (2) infringe any intellectual property right of
            any third party. You hereby irrevocably agree to indemnify, defend
            and hold Traxa, its affiliates, directors, officers, employees and
            agents harmless from and against any and all loss, costs, damages,
            liabilities and expenses arising out of or related to any third
            party claim resulting from a breach of the foregoing representations
            and warranties.
          </li>
          <li>
            Software: To the extent that the Site contains Traxa Content or
            Third Party Content that is software available for download
            (“Software”), such Software is subject to, and your use of such
            Software is governed by, an applicable license agreement (each a
            “License Agreement”) accompanying, provided with, linked to or
            embedded in, such Software. You may not use any Software unless you
            first read and agree to all of the terms and conditions of the
            applicable License Agreement. ANY SOFTWARE AVAILABLE ON THE SITE IS
            WARRANTED, IF AT ALL, IN ACCORDANCE WITH THE TERMS AND CONDITIONS OF
            THE APPLICABLE LICENSE AGREEMENT. EXCEPT AS MAY BE EXPRESSLY SET
            FORTH IN THE APPLICABLE LICENSE AGREEMENT, OR TO THE EXTENT NOT
            PERMITTED UNDER APPLICABLE LAW, Traxa EXPRESSLY DISCLAIMS ANY AND
            ALL IMPLIED OR STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION,
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT.
          </li>
          <li>
            Trademarks: All trademarks, service marks and logos included on the
            Site (“Marks”) are the property of Traxa or third parties, and you
            may not use such Marks without the express, prior written consent of
            Traxa or the applicable third party.
          </li>
          <li>
            Monitoring of Site Content: Traxa may, but is not obligated to,
            monitor Site Content, User Content and your use of the Site. Traxa
            reserves the right to accept, reject or modify any Site Content or
            User Content, but assumes no liability based on its acceptance,
            rejection, modification or failure to modify any Site Content or
            User Content.
          </li>
        </ul>

        <h2 className="pt-8 text-lg font-bold text-white">
          Your Use of the Site
        </h2>

        <ul className="space-y-4 list-decimal">
          <li>
            Submission of User Content: In connection with your use of the Site,
            you agree not to submit any User Content which (i) is unlawful, (ii)
            infringes or violates the intellectual property or other rights of
            any person or entity, (iii) violates any right of publicity or
            invades the privacy of others, (iv) constitutes obscene,
            pornographic, indecent, profane or otherwise objectionable material,
            (v) is discriminatory, hateful or bigoted toward, or abusive of, any
            group or individual, (vi) is libelous or defamatory, (vii) violates
            any applicable law or government regulation, (viii) breaches any
            duty of confidentiality that you owe to anyone, (ix) provides any
            non­public information about Traxa or any other company or person
            without authorization (including, but not limited to, the names and
            contact information of Traxa employees or other Registered Users).
          </li>
          <li>
            Data Privacy Compliance: You may not use the Site to post, transmit
            or link to individual’s personal data. You should always be mindful
            of the privacy of others and use discretion when sharing any private
            content relating to or belonging to others (including phone numbers,
            social security numbers, addresses, financial information,
            passwords, and government identification).
          </li>
          <li>
            Prohibited Conduct: You may not use the Site to post, transmit or
            link to (i) any advertisement, promotional materials or solicitation
            related to any product or service that is competitive with Traxa
            products or services or (ii) software or programs which contain any
            harmful code, including, but not limited to, viruses, worms, time
            bombs or Trojan horses. You may not deploy to the Site any robot,
            spider, site search/retrieval application, or other application
            designed to retrieve, index, “data mine,” information from the Site.
          </li>
          <li>
            Registered Users: If you become a registered user of the Site in
            order to gain access to support or other limited access parts of the
            Site, you agree (i) that the information you provide to Traxa in
            connection with your registration will be current, complete and
            accurate, (ii) not to choose a user name that violates any law or
            the intellectual property rights of others, or is offensive;
            provided that Traxa reserves the right to reject the use of any user
            name for any reason or no reason, (iii) not to transfer, sell,
            convey or assign the right to use the Site to any third party
            without the prior written consent of Traxa and (iv) not to permit
            any third party to use your user name and password to access the
            Site. You further agree that you are responsible for the conduct of
            any party that uses your account, whether or not authorized by you,
            and for any breach of the security of the Site related to the use of
            your user name and/or password.
          </li>
          <li>
            Indemnification: You hereby irrevocably agree to indemnify, defend
            and hold Traxa, its affiliates, directors, officers, employees and
            agents harmless from and against any and all losses, costs, damages,
            liabilities and expenses (including attorneys’ fees) arising out of
            or related to you use of the Site.
          </li>
          <li>
            Export Control: You hereby represent and warrant that (i) you
            understand and acknowledge that some Site Content may be subject to
            export, re-export and import restrictions under applicable law, (ii)
            you will not use the Site or any Site Content in a manner that
            violates the U.S. Export Administration Act of 1979 and the
            regulations of the U.S. Department of Commerce and (iii) you are not
            located in, under the control of, or a national or resident of any
            country to which the United States has embargoed goods.
          </li>
        </ul>

        <h2 className="pt-8 text-lg font-bold text-white">Linked Websites</h2>

        <p>
          The Site may contain links to other websites that are not owned or
          operated by Traxa, and you agree that Traxa provides links to such
          websites solely as a convenience and has no responsibility for the
          content or availability of such websites, and that Traxa does not
          endorse such websites or any products or services associated
          therewith. Your use of such websites will be subject to the terms of
          use applicable to each such website.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">
          Disclaimer of Warranties and Limitation of Liability
        </h2>

        <ul className="space-y-4 list-decimal">
          <li>
            Warranty Disclaimer: THE SITE AND THE SITE CONTENT ARE PROVIDED
            STRICTLY ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND Traxa MAKES NO
            WARRANTY THAT THE SITE OR THE SITE CONTENT IS COMPLETE, SUITABLE FOR
            YOUR PURPOSE, OR ACCURATE. ON BEHALF OF ITSELF AND ITS LICENSORS,
            Traxa HEREBY EXPRESSLY DISCLAIMS ANY AND ALL IMPLIED, STATUTORY OR
            OTHER WARRANTIES WITH RESPECT TO THE SITE, AVAILABILITY OF THE SITE
            AND THE SITE CONTENT, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NONINFIRNGEMENT. THE ENTIRE RISK AS TO RESULTS OBTAINED THROUGH USE
            OF THE SITE AND/OR THE SITE CONTENT RESTS WITH YOU AND Traxa MAKES
            NO REPRESENTATION OR WARRANTY THAT THE SITE AVAILABILITY WILL BE
            UNINTERRUPTED, OR THE SITE AND/OR THE SITE CONTENT WILL BE ERROR
            FREE.
          </li>
          <li>
            Limitation of Liability: TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW, YOU AGREE THAT Traxa SHALL NOT BE LIABILE TO YOU FOR
            ANY DAMAGES ARISING OUT OF OR CONNECTED TO YOUR USE OF, OR INABILITY
            TO USE, THE SITE, INCLUDING, WITHOUT LIMITATION, ANY AND ALL DIRECT,
            INDIRECT, INCIDENTAL,CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY OR
            STATUTORY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF BUSINESS,
            LOSS OR PROFITS, LOSS OF REVENUE, LOSS OF DATA., LOSS OF GOODWILL OR
            FOR ANY COST OF COVER OR COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
            SERVICES), EVEN IF Traxa HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, AND REGARDLESS OF THE LEGAL THEORY UNDER WHICH DAMAGES ARE
            SOUGHT, WHETHER IN BREACH OF CONTRACT OR IN TORT, INCLUDING
            NEGLIGENCE.E.
          </li>
        </ul>

        <h2 className="pt-8 text-lg font-bold text-white">Privacy</h2>

        <p>
          You acknowledge that you have read, understand and agree to Traxa’s
          Privacy Policy located at{" "}
          <a
            className="underline"
            href="https://www.app.trackmycontainer.io/privacy"
          >
            https://www.app.trackmycontainer.io/privacy
          </a>
          .
        </p>

        <h2>Location of Site and your Use</h2>

        <p>
          Traxa operates or controls the operation of this Site from offices in
          Wyoming, USA. In addition, the Site may be mirrored, and other
          websites operated or controlled by Traxa may be located, in various
          countries. Traxa makes no representation or warranty that all of the
          features of this Site will be available to you from specific
          countries, or that they are permitted to be accessed from any country.
          You acknowledge that you are solely responsible for any decision by
          you to use of this Site from your locations, and that such use may be
          subject to, and that you are responsible for, compliance with
          applicable local laws.
        </p>

        <h2 className="pt-8 text-lg font-bold text-white">General</h2>

        <p>
          These Terms of Use constitute the entire agreement and understanding
          between the parties concerning the subject matter hereof,
          notwithstanding any different or additional terms that may be
          contained in the form of purchase order or other document used by you
          to place orders or otherwise effect transactions hereunder, which such
          terms are hereby rejected. These Terms of Use supersede all prior or
          contemporaneous discussions, proposals and agreements between you and
          Traxa relating to the subject matter hereof. No amendment,
          modification or waiver of any provision of these Terms of Use will be
          effective unless in writing and signed by an officer of Traxa. If any
          provision of these Terms of Use is held to be invalid or
          unenforceable, the remaining portions will remain in full force and
          effect and such provision will be enforced to the maximum extent
          possible so as to effect the intent of the parties and will be
          reformed to the extent necessary to make such provision valid and
          enforceable. No waiver of rights by Traxa may be implied from any
          actions or failures to enforce rights under these Terms of Use. These
          Terms of Use are intended to be and are solely for the benefit of
          Traxa and you and do not create any right in favor of any third party.
          These Terms of Use will be governed by and construed in accordance
          with the laws of the United States without reference to its conflict
          of laws principles. All disputes arising out of or relating to these
          Terms of Use will be submitted to the exclusive jurisdiction of a
          court of competent jurisdiction located in the United States, and each
          party irrevocably consents to such personal jurisdiction and waives
          all objections to this venue. All notices must be in writing and will
          be effective three (3) days after the date sent.
        </p>
      </VStack>

      <ContactLinks className="pt-24 pb-16" />
    </VStack>
  );
}

export default Terms;
