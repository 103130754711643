import {
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  HStack,
  Image,
  Badge,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import { FrameDialog } from "../components/FrameDialog";
import { containerIcon } from "../components/Icons";
import { fetchContainer } from "../services/ContainerApi";
import {
  MagnifyingGlassIcon,
  CameraIcon,
  CubeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { InputRightElement } from "@chakra-ui/react";
import { Circle } from "@chakra-ui/react";
import { LatestContainers } from "../components/LatestContainers";
import {
  PhotoIcon,
  UserCircleIcon,
  ClockIcon,
  ShareIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { Divider } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import "react-spring-bottom-sheet/dist/style.css";
import { Drawer } from "@chakra-ui/react";
import { DrawerOverlay } from "@chakra-ui/react";
import { DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton } from "@chakra-ui/react";
import { DrawerHeader } from "@chakra-ui/react";
import { DrawerBody } from "@chakra-ui/react";
import { DrawerFooter } from "@chakra-ui/react";
import ContainerMapResult from "../components/ContainerMapResult";
import { sendSearchEvent } from "../utils/ga-utils";

function Map() {
  document.title = "Traxa | Explorer";
  const toast = useToast();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  const [containerId, setContainerId] = useState(
    searchParams.get("containerId")
  );

  const [frameUrl, setFrameUrl] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [isPanelOpen, setPanelOpen] = useState(true);

  const map = useRef();
  const searchBar = useRef();

  const chains = {
    P: { fullName: "Polygon", explorer: "https://polygonscan.com/tx/" },
  };

  useEffect(() => {
    searchBar.current.value = containerId;

    //Fetch container
    async function searchContainer() {
      setLoading(true);
      let res = await fetchContainer(containerId);

      //Filter containers on same location
      const distinct = [];
      if (res.length > 0) distinct.push(res[0]);

      for (let i = 1; i < res.length; i++) {
        const it = res[i];
        if (
          distinct.find(
            (x) => x.gps_lat === it.gps_lat && x.gps_lon === it.gps_lon
          )
        )
          continue;

        distinct.push(it);
      }

      setContainers(distinct);
      setLoading(false);

      //Save search
      sendSearchEvent(containerId);

      //Fly to container position
      if (res.length > 0) {
        map.current.flyTo(
          [parseFloat(res[0].gps_lat), parseFloat(res[0].gps_lon)],
          17,
          {
            animate: true,
            duration: 0.5,
          }
        );
      }
    }

    searchContainer().catch(console.error);
  }, [containerId]);

  useEffect(
    () => setContainerId(searchParams.get("containerId")),
    [searchParams]
  );

  function search(query) {
    if (query && query !== "") navigate("/map?containerId=" + query);
  }

  function share() {
    const url =
      "https://app.trackmycontainer.io/map?containerId=" + containers[0].text;

    navigator.clipboard.writeText(url).then(() =>
      toast({
        title: "Link copied to clipboard",
        status: "success",
        isClosable: true,
      })
    );
  }

  function openForm(url) {
    setFrameUrl(url);
    onOpen();
  }

  return (
    <>
      {isPanelOpen && (
        <div className="absolute z-[900] top-20 sm:top-8 left-4 -sm:right-4 bottom-4 flex flex-col">
          <div
            className="flex justify-end -mb-6 w-full h-16 text-white rounded-t-xl max-w-[24em] bg-orange-500 p-2 sm:hidden cursor-pointer"
            onClick={() => setPanelOpen(!isPanelOpen)}
          >
            Show map
            <ChevronDownIcon className="py-0 h-7" />
          </div>
          <ContainerMapResult
            containers={containers}
            isLoading={loading}
            shareAction={share}
            notFoundAction={openForm}
          />
        </div>
      )}

      {!isPanelOpen && (
        <div className="absolute z-[900] bottom-6 left-4 right-4">
          <div
            className="flex justify-end items-center w-full h-12 text-white rounded-xl max-w-[24em] bg-orange-500 p-2 sm:hidden cursor-pointer"
            onClick={() => setPanelOpen(!isPanelOpen)}
          >
            Show container <ChevronUpIcon className="py-0 ml-2 h-7" />
          </div>
        </div>
      )}

      <FrameDialog onClose={onClose} isOpen={isOpen} pageUrl={frameUrl} />

      <div className="fixed ml-4 sm:ml-10 mt-4 sm:mt-8 z-[900] sm:left-96 top-0 flex">
        <HStack className="px-2 rounded-md">
          <InputGroup className="bg-white rounded-full shadow-md">
            <InputLeftElement
              pointerEvents="none"
              children={<MagnifyingGlassIcon width={20} />}
            />
            <Input
              placeholder="Search container..."
              defaultValue={containerId}
              variant="outline"
              ref={searchBar}
              onKeyDown={(e) => {
                if (e.key === "Enter") search(searchBar.current.value);
              }}
              className="bg-white !rounded-full !border-none"
            />
          </InputGroup>
          <Button
            variant="solid"
            colorScheme="orange"
            children="Track"
            className="!bg-gradient-to-tr from-purple-500 to-orange-500 !rounded-full"
            onClick={() => search(searchBar.current.value)}
          />
        </HStack>
      </div>

      <MapContainer
        style={{ height: "100vh" }}
        center={[54.989, 73.369]}
        zoom={3}
        zoomControl={false}
        ref={map}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {containers &&
          containers.map((it) => (
            <Marker
              key={it}
              position={[parseFloat(it.gps_lat), parseFloat(it.gps_lon)]}
              icon={containerIcon}
            />
          ))}
      </MapContainer>
    </>
  );
}

export default Map;
