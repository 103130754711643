import { Img } from "@chakra-ui/react";
import { HStack } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";

function Stats() {
  document.title = "Traxa | Stats";

  return (
    <VStack h="100vh" w="100vw" className="py-4 text-purple-200 bg-traxa">
      <HStack>
        <a href="/">
          <Img
            src="logo_white.png"
            alt="Traxa logo"
            className="aspect-[770/256]"
            maxW="500px"
            width="100px"
          />
        </a>
      </HStack>
    </VStack>
  );
}

export default Stats;
