const apiBase = "https://api.trackmycontainer.io/assets/";

async function fetchContainer(id) {
  try {
    const res = await fetch(apiBase + "?text=" + id);
    return await res.json();
  } catch (e) {
    return [];
  }
}

async function fetchLatestContainers(limit = 10) {
  try {
    const res = await fetch(apiBase);
    return await res.json();
  } catch (e) {
    return [];
  }
}

async function getDailyScannedContainers() {
  try {
    const res = await fetch("https://api.trackmycontainer.io/assets/daily");
    return await res.json();
  } catch (e) {
    return [];
  }
}

export { fetchLatestContainers, fetchContainer };
