import {
  useDisclosure,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { LatestContainers } from "./LatestContainers";

function FrameDialog({ pageUrl, onClose, isOpen }) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent className="!w-full !max-w-2xl !h-[85%]">
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            className="!w-full"
          >
            Let's get in touch
          </AlertDialogHeader>

          <AlertDialogBody className="!h-full">
            <iframe
              className="w-full h-full"
              src={pageUrl}
              border="0"
              title="Contact dialog"
            ></iframe>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>Close</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export { FrameDialog };
